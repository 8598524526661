*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

form {
  margin-top: -8;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  // @include font-reg;
}

ul {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root,
#__next {
  isolation: isolate;
}

input,
button,
select,
textarea {
  padding: 0;
  font: inherit;
  background: transparent;
  color: inherit;
  outline: none;
}