@import '../../styles/partials/mixins';

main {
    padding: 0 2rem 0 2rem;

    @include tablet {
        padding: 0 2.5rem 0 2.5rem;
    }

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 6.25rem 0 6.25rem;
    }

    @include desktop-large {
        padding: 0 6.25rem 3.75rem 6.25rem;
    }
}

.hero {
    height: 28.75rem;
    padding: 0 3vw;
    display: flex;

    @include tablet {
        align-items: center;
        height: 31.25rem;
        padding: 0 1.5rem;
    }

    @include desktop {
        align-items: center;
        height: 43.75rem;
        padding: 0 2rem;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 2rem;

        @include tablet {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding-top: 6.25rem;
            gap: 2.5rem;
            width: 100%;
        }

        @include desktop {
            align-items: center;
            justify-content: center;
            gap: 2.5rem;
        }
    }

    &__breakpoint-wrap {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 4vw;
        margin-bottom: 1.5rem;

        @include tablet {}

        @include desktop {
            max-width: none;
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }

    }

    // Hero Text Visibility
    &__tablet-title-wrap {
        display: none;

        @include tablet {
            display: unset;
        }

        @include desktop {
            display: none;
        }

    }

    &__desktop-title-wrap {
        display: unset;

        @include tablet {
            display: none;
        }

        @include desktop {
            display: unset;
        }

        @include desktop-large {
            min-width: 31.75rem;
        }
    }

    &__container-text {
        padding: 0 1rem;

        @include tablet {
            padding: 0;
        }
    }

    // Hero Text Styling
    &__title {
        font-size: 9vw;
        line-height: 6vw;
        @include font-bold;
        margin-bottom: 0.5rem;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);

        @include tablet {
            font-size: 4rem;
            line-height: 2.5rem;
            margin-bottom: 1rem;
        }

        @include desktop {
            font-size: 3.75rem;
            line-height: 4.125rem;
            margin-bottom: 0.5rem;
        }

        @include desktop-large {
            font-size: 4.5rem;
            line-height: 4.125rem;
            margin-bottom: 1rem;
        }
    }

    &__subtitle {
        font-size: 7vw;
        height: auto;
        // line-height: 5vw;
        @include font-light;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);
        border-right: .1em solid;
        white-space: nowrap;
        overflow: hidden;
        width: 15ch;
        /* Adjust as needed */
        animation: typingAndBackspacing 11s steps(60, end) infinite,
            blink-caret .75s step-end infinite;
        ;


        @include tablet {
            font-size: 3rem;
            line-height: 3.2rem;
            margin-bottom: 2rem;
        }

        @include desktop {
            font-size: 3rem;
            line-height: 3.4rem;
        }

        @include desktop-large {
            font-size: 3.25rem;
        }
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    @keyframes backspace {
        from {
            width: 100%;
        }

        to {
            width: 0;
        }
    }

    @keyframes blink-caret {
        50% {
            border-color: transparent;
        }
    }

    &__bio {
        font-size: 1rem;
        @include font-light;
        margin-bottom: 1rem;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);

        @include tablet {
            font-size: 1.25rem;
            line-height: 1.625rem;
            max-width: 31.25rem;
            margin-bottom: 0.5rem;
        }

        @include desktop {
            font-size: 1.5rem;
            line-height: 2.0625rem;
        }
    }


    // Hero Github Styling
    &__github {
        font-size: 1.125rem;
        line-height: 1.375rem;
        @include font-light;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);

        @include tablet {
            font-size: 1.125rem;
            line-height: 2.75rem;
        }

        @include desktop {
            font-size: 1.5rem;
            line-height: 2.75rem;
        }
    }

    @include tablet {
        font-size: 2.5rem;
        line-height: 2.75rem;
        @include font-light;
    }

    @include desktop {
        font-size: 2.5rem;
        line-height: 2.75rem;
        @include font-light;
    }

    &__github-logo {
        display: inline-block;
        height: 1.875rem;
        width: 1.875rem;
        margin-left: 0.5rem;
        transform: translateY(0.5rem);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

        @include tablet {
            height: 2.5rem;
            width: 2.5rem;
        }

        @include desktop {
            height: 2.5rem;
            width: 2.5rem;
        }
    }


    // Hero Image Styling
    &__headshot {
        border-radius: 50%;
        height: 23vw;
        box-shadow: 2px 2px 0 1px white;

        @include tablet {
            box-shadow: 6px 6px 0 2px white;
            max-width: 18.75rem;
            max-height: 18.75rem;
        }

        @include desktop {
            max-width: 18.75rem;
            margin: 0;
        }
    }
}

.footer {
    padding: 2rem;
    background-color: #192b55;
    color: #fff;
    text-align: center;

    @include tablet {
        padding: 2.5rem 3.5rem;
    }

    @include desktop {
        padding: 2.5rem 9.75rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 0.625rem;

        @include tablet {
            flex-direction: row;
            gap: 2.5rem;
        }

        @include desktop {
            justify-content: center;
            gap: 6.25rem;
        }
    }

    &__name,
    &__phone,
    &__email {
        margin: 0;
    }
}

// Animations

@keyframes typingAndBackspacing {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    78% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}