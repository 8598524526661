@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';


.header {
    height: 3.75rem;
    width: 100%;
    position: absolute;
    display: flex;

    @include tablet {
        padding: 0 1.5rem;
    }

    @include desktop {
        padding: 0 2rem;
    }

    &-nav {
        display: flex;
        align-items: center;
        height: 100%;

        ul {
            display: flex;

            li {
                padding: 0 1rem;

                a {
                    cursor: pointer;
                    color: $white;
                    font-weight: bold;
                    transition: color 0.2s ease-in-out;
                    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4);
                }
            }
        }

        &:hover {
            li a {
                color: $silver;
            }
        }

        li a:hover {
            color: $white;
        }
    }
}

.modal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8); // Black with 80% opacity
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3dvh;
    width: 100%;
    height: 100%;
    z-index: 1;

    animation: fadeIn 0.5s ease-in-out;

    &.closing {
        animation: fadeOut 0.5s ease-in-out;
    }

    &__button {
        @include modal-button-tablet-desktop;
    }


    &__pdf {
        width: 90vw;
        height: calc(94vw * 11 / 8.5); // 90% of the viewport width times the aspect ratio (11 / 8.5
        margin-bottom: 2rem;

        @include tablet {
            width: 60vw;
            height: calc(60vw * 11 / 8.5); // 90% of the viewport width times the aspect ratio (11 / 8.5
        }

        @include desktop {
            width: calc(70vh * 8.5 / 11); // 80% of the viewport height times the aspect ratio (8.5 / 11)
            height: 73vh;
        }

    }

    &__contact {
        background-color: $white;
        background-color: #272475;
        color: #272475;
        width: fit-content;
        height: auto;
        overflow: visible;
        padding: 0 2rem;
        padding-bottom: 1.5rem;
        border-radius: 6px;


        @include tablet {
            width: 25rem;
            height: 16.25rem;
            padding: 0 3rem;
        }

        &--header {
            @include h2-mobile;
            margin-bottom: 0.5rem;
            margin-top: 1.5rem;

            @include tablet {
                @include h2-tablet__desk;
                margin-bottom: 1rem;
                margin-top: 2.5rem;
            }
        }

        &--anchor {
            color: $mercury;
            @include p-mobile;

            @include tablet {
                @include p-tablet__desk;
            }

            &:hover {
                color: $white;
            }
        }
    }
}


// Fade in modal animation

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}