// Colors
$silver: #afafaf;
$white: #fff;
$light-black: #323232;
$darkblack: #000;
$mercury: #e1e1e1;
$alabaster: #fafafa;


// Breakpoints
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
$desktop-large-breakpoint: 1375px;