@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';


.skills {
    @include desktop {
        width: 90%;
    }

    &__header {
        font-size: 2.5rem;
        @include font-bold;
        margin-bottom: 1.5rem;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);

        @include tablet {
            margin-bottom: 2.75rem;
        }

    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // creates two columns
        row-gap: 1.5rem;
        column-gap: 0.5rem;
        justify-items: center;
        text-align: center;
        padding: 0 0.5rem;

        @include tablet {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;
        }

        @include desktop {
            padding: 0 1rem;
            gap: 2.5rem;
        }
    }
}