@font-face {
    font-family: 'Nunito Sans'; // You can give any name to your font
    src: url('../../assets/fonts/static/NunitoSans_10pt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans'; // You can give any name to your font
    src: url('../../assets/fonts/static/NunitoSans_10pt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans'; // You can give any name to your font
    src: url('../../assets/fonts/static/NunitoSans_10pt-Light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}