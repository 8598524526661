@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.skill {
    display: flex;
    align-items: center;

    @include tablet {
        align-items: center;
    }


    &__logo {
        height: 2.5rem;
        max-height: 2.5rem;
        max-width: 6.25rem;
        object-fit: contain;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

        @include tablet {
            height: 2.5rem;
            max-height: 2.5rem;
            max-width: 7.75rem;
        }

        @include desktop {
            height: 3.125rem;
            max-height: 3.125rem;
            max-width: 8.25rem;
        }
    }

    &__logo-title {
        margin-left: 0.5rem;
        font-size: 1.125rem;
        text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.4);

        @include tablet {
            font-size: 1.5rem;
        }

        @include desktop {
            font-size: 1.5rem;
        }
    }
}


@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(4deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.wiggle {
    animation: wiggle 0.5s ease-in-out;
}